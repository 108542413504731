import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileUploader, FileItem } from 'ng2-file-upload/ng2-file-upload';

import { QuillEditorComponent } from 'ngx-quill/src/quill-editor.component';
import { ConstructiveDetailService } from 'app/services/constructiveDetail.service';
import { ConstructiveDetail, Media, Category, Attributes, Link, Article, Project, Minisite } from 'app/models';
import { CategoryService } from 'app/services/category.service';
import { first, debounceTime, switchMap } from 'rxjs/operators';
import { NGXToastrService } from 'app/services/ngx.toastr.service';
import { MLItem } from 'app/models/mlitem';
import { SlugifyPipe } from 'app/pipes/slugify.pipe';
import { MediaService } from 'app/services/media.service';
import { ElementTypeService } from 'app/services/elementTypes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentService } from 'app/services/entity.service';
import swal from 'sweetalert2';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';
import { ArticleService } from 'app/services/article.service';
import { ProjectService } from 'app/services/project.service';

import * as QuillNamespace from 'quill';
import ImageResize from 'quill-image-resize-module';
import { AuthenticationService } from 'app/services/authentication.service';
import { ClipboardService } from 'ngx-clipboard';
import * as moment from 'moment';

@Component({
  selector: 'app-publications-constructive-detail',
  templateUrl: './publications-constructive-detail.component.html',
  styleUrls: ['./publications-constructive-detail.component.scss']
})
export class PublicationsConstructiveDetailComponent implements OnInit, OnDestroy {
  saving: boolean = false;
  validating: boolean = false;
  isAdmin: boolean = false;
  step: string = 'first';
  data: ConstructiveDetail = new ConstructiveDetail();
  URNFile: File;
  isLinear: boolean = false;
  isEdit: boolean = false;

  sub: Subscription = new Subscription();

  articleFormGroup: FormGroup;
  extendedFormGroup: FormGroup;

  @ViewChild('editor') editor: QuillEditorComponent;
  editor_modules: any = {};
  quillEditorRefEs: any;
  quillEditorRefEn: any;
  quillEditorSubsRefEs: any;
  quillEditorSubsRefEn: any;

  uploaderHeaderImg: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
    isHTML5: true
  });

  uploaderTechnicalDetailImg: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
    isHTML5: true
  });

  uploaderSequenceImg: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
    queueLimit: 12,
    isHTML5: true
  });
  sequenceFiles: Media[] = [];
  documentsFiles: Media[] = [];
  categories: Category[] = [];
  urlDocument: string = "";
  uploaderDocuments: FileUploader = new FileUploader({
    url: '',
    isHTML5: true
  });
  minisites: Minisite[] = [];

  doc_types: any[] = [];
  selectedCategories: string[] = [];

  hasBaseDropZoneOver = false;
  hasAnotherDropZoneOver = false;

  complementary_fields: Attributes[] = [];

  linkRelatedConstructionDetails: Link[] = [];
  linkRelatedArticles: Link[] = [];
  linkRelatedProjects: Link[] = [];
  linkProject: Link = new Link();
  filteredConstructiveDetails: Observable<ConstructiveDetail>;
  filteredArticles: Observable<Article>;
  filteredProjects: Observable<Project>;
  filteredProject: Observable<Project>;

  nestedTreeControl: NestedTreeControl<any>;
  nestedDataSource: MatTreeNestedDataSource<any>;

  selectedMinisites: string[] = [];
  nestedTreeControlMinisites: NestedTreeControl<any>;
  nestedDataSourceMinisites: MatTreeNestedDataSource<any>;

  constructor(
    private _location: Location,
    private formBuilder: FormBuilder,
    private constructiveDetailService: ConstructiveDetailService,
    private articleService: ArticleService,
    private projectService: ProjectService,
    private categoryService: CategoryService,
    private ngxToastrService: NGXToastrService,
    private slugifyPipe: SlugifyPipe,
    private mediaService: MediaService,
    private elementTypeService: ElementTypeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private authService: AuthenticationService,
    private clipboardService: ClipboardService
  ) {
    this.nestedTreeControl = new NestedTreeControl<any>(this._getChildren);
    this.nestedTreeControlMinisites = new NestedTreeControl<any>(this._getChildrenMinisites);
    this.nestedDataSource = new MatTreeNestedDataSource();
    this.nestedDataSourceMinisites = new MatTreeNestedDataSource();
  }

  hasNestedChild = (_: number, nodeData: any) => nodeData.children;
  hasNestedChildMinisites = (_: number, nodeDataMinisites: any) => nodeDataMinisites.children;

  private _getChildren = (node: any) => node.children;
  private _getChildrenMinisites = (nodeMinisites: any) => nodeMinisites.children;

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.getCategories();
    this.getDocType();
    this.getNestedCategories('parents');
    this.getMinisites();
    this.getNestedMinisites('parents');
    this.quillEditorConfig();

    this.sub = this.authService.admin.subscribe(isAdmin => this.isAdmin = isAdmin)

    this.articleFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
      title_en: [''],
      project: [''],
      author: ['', Validators.required],
      authorLink: [''],
      location: ['', Validators.required],
      year: [''],
      collaborators: [''],
      keywords: ['', Validators.required],
      photographer: [''],
      photographerLink: [''],
      block: ['', Validators.required],
    });

    const now = moment();

    if(this.isAdmin) {

      this.extendedFormGroup = this.formBuilder.group({
        content: ['', Validators.required],
        content_en: [''],
        contentSubscribers: [''],
        contentSubscribers_en: [''],
        privacy: [false, Validators.required],
        opened: [false],
        draft: [true],
        date_publish: [this.dateFormat(new Date(), '-'), Validators.required],
        hour_publish: [now.hour(), [Validators.required, Validators.pattern('[01]?[0-9]|2[0-3]')]],
        linkRelatedConstructionDetails: [''],
        linkRelatedArticles: [''],
        linkRelatedProjects: [''],
        minisites: [this.selectedMinisites]
      });

    }else{

      this.extendedFormGroup = this.formBuilder.group({
        content: ['', Validators.required],
        content_en: [''],
        contentSubscribers: [''],
        contentSubscribers_en: [''],
        privacy: [false, Validators.required],
        opened: [false],
        draft: [true],
        date_publish: [this.dateFormat(new Date(), '-'), Validators.required],
        hour_publish: [now.hour(), [Validators.required, Validators.pattern('[01]?[0-9]|2[0-3]')]],
        linkRelatedConstructionDetails: [''],
        linkRelatedArticles: [''],
        linkRelatedProjects: ['']
      });

    }

    let slug = this.activatedRoute.snapshot.paramMap.get('slug');

    if(slug) {

      this.isEdit = true;

      let allowMinisites = 0;

      if(this.isAdmin) {
        allowMinisites = 1;
      }

      this.contentService.getContent(slug, allowMinisites)
        .pipe(first())
        .subscribe(
          (data: ConstructiveDetail) => {
            this.data = data;

            for(const cat of data.category) {
              this.selectedCategories.push(cat.id);
            }

            let keywords = "";
            if(data.keywords) {
              for(const key of data.keywords) {
                if(key) keywords += `${key},`;
              }
            }

            if(this.isAdmin && data.minisites){
              for(const mst of data.minisites) {
                if(mst) { // @ts-ignore
                  this.selectedMinisites.push(mst);
                }
              }
            }

            this.articleFormGroup = this.formBuilder.group({
              title: [data.title.es, Validators.required],
              title_en: [data.title.en],
              project: [''],
              author: [data.author, Validators.required],
              authorLink: [data.authorLink],
              location: [data.location, Validators.required],
              year: [data.year],
              collaborators: [data.collaborators],
              keywords: [keywords, Validators.required],
              photographer: [data.photographer],
              photographerLink: [data.photographerLink],
              block: [this.selectedCategories, Validators.required],
            });

            const publishedDate = moment(data.datePublish);

            let descriptionSubscribersES: string = '';
            let descriptionSubscribersEN: string = '';
            if(data.descriptionSubscribers) {
              if(data.descriptionSubscribers.es) descriptionSubscribersES = data.descriptionSubscribers.es
              if(data.descriptionSubscribers.en) descriptionSubscribersEN = data.descriptionSubscribers.en
            }

            if(this.isAdmin) {

              this.extendedFormGroup = this.formBuilder.group({
                content: [data.description.es, Validators.required],
                content_en: [data.description.en],
                contentSubscribers: [descriptionSubscribersES],
                contentSubscribers_en: [descriptionSubscribersEN],
                privacy: [true, Validators.required],
                opened: [data.opened],
                draft: [data.draft],
                date_publish: [data.datePublish, Validators.required],
                hour_publish: [publishedDate.hour(), Validators.required],
                linkRelatedConstructionDetails: [''],
                linkRelatedArticles: [''],
                linkRelatedProjects: [''],
                createdByTectonica: [data.createdByTectonica, Validators.required],
                viewerVisibility: [data.viewerVisibility, Validators.required],
                minisites: [this.selectedMinisites],
              });

            }else{

              this.extendedFormGroup = this.formBuilder.group({
                content: [data.description.es, Validators.required],
                content_en: [data.description.en],
                contentSubscribers: [descriptionSubscribersES],
                contentSubscribers_en: [descriptionSubscribersEN],
                privacy: [true, Validators.required],
                opened: [data.opened],
                draft: [data.draft],
                date_publish: [data.datePublish, Validators.required],
                hour_publish: [publishedDate.hour(), Validators.required],
                linkRelatedConstructionDetails: [''],
                linkRelatedArticles: [''],
                linkRelatedProjects: ['']
              });

            }

            if(data.project) {
              const project: Project = <Project>data.project;

              let link = new Link();
              link.referenceId = project.id;
              link.name = project.title.es;
              link.slug = project.slug;
              link.type = "project";
              link.url = `/projects/${project.slug}`;

              this.linkProject = link;
            }

            this.sequenceFiles = data.images || [];
            this.documentsFiles = data.documents || [];
            this.complementary_fields = data.complementaryFields || [];
            this.linkRelatedConstructionDetails = data.linkRelatedConstructionDetails || [];
            this.linkRelatedArticles = data.linkRelatedArticles || [];
            this.linkRelatedProjects = data.linkRelatedProjects || [];

            this.loadAutocompletes();
          },
          error => {
            this.router.navigate(['/page-not-found'])
            this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos del contenido')
          });
    } else {
      this.loadAutocompletes();
    }
  }

  back() {
    this._location.back();
  }

  quillEditorConfig() {
    let Quill: any = QuillNamespace;
    Quill.register('modules/imageResize', ImageResize);

    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['clean'], // remove formatting button
          ['link', 'image', 'video']
        ]
      },
      imageResize: true
    };
  }

  getEditorInstance(editorInstance: any, type: string) {
    if (type === 'es') {
      this.quillEditorRefEs = editorInstance;
    } else if(type === 'en') {
      this.quillEditorRefEn = editorInstance;
    } else if(type === 'sub_es') {
      this.quillEditorSubsRefEs = editorInstance;
    } else {
      this.quillEditorSubsRefEn = editorInstance;
    }

    const toolbar = editorInstance.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
    editorInstance.focus();
  }

  imageHandler = (image, callback) => {
    const input = <HTMLInputElement> document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        const reader  = new FileReader();
        reader.onload = async () =>  {
          const fileData = new FormData();
          fileData.append('media', file, this.slugifyPipe.transform(file.name))
          fileData.append('type', file.type.split('/')[1]);
          fileData.append('name', this.slugifyPipe.transform(file.name));

          let s3Url: string;

          await this.mediaService.uploadImage(fileData)
            .toPromise().then( (url) => {
              s3Url = url.toString();

              const rangeEs = this.quillEditorRefEs.getSelection();
              const rangeEn = this.quillEditorRefEn.getSelection();
              const rangeSubsEs = this.quillEditorSubsRefEs.getSelection();
              const rangeSubsEn = this.quillEditorSubsRefEn.getSelection();
              const img = '<img src="' + s3Url + '" />';
              if (rangeEs !== null) {
                this.quillEditorRefEs.clipboard.dangerouslyPasteHTML(rangeEs.index, img);
              } else if (rangeEn !== null) {
                this.quillEditorRefEn.clipboard.dangerouslyPasteHTML(rangeEn.index, img);
              } else if (rangeSubsEs !== null) {
                this.quillEditorSubsRefEs.clipboard.dangerouslyPasteHTML(rangeSubsEs.index, img);
              } else if (rangeSubsEn !== null) {
                this.quillEditorSubsRefEn.clipboard.dangerouslyPasteHTML(rangeSubsEn.index, img);
              }
            });
        };
        reader.readAsBinaryString(file);
      } else {
        this.ngxToastrService.typeError('Error', 'Solo se pueden subir imágenes en el editor');
      }
    };
    input.click();
  };

  onChangeCategory($event) {
    if($event.checked) {
      this.selectedCategories.push($event.source.value)
    } else {
      this.selectedCategories = this.selectedCategories.filter( value => value !== $event.source.value)
    }

    this.articleFormGroup.controls['block'].setValue(this.selectedCategories)
  }

  onChangeMinisite($event) {
    if($event.checked) {
      this.selectedMinisites.push($event.source.value)
    } else {
      this.selectedMinisites = this.selectedMinisites.filter( value => value !== $event.source.value)
    }

    this.extendedFormGroup.controls['minisites'].setValue(this.selectedMinisites)
  }

  getMinisites() {
    this.constructiveDetailService.getMinisites('')
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.minisites = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de minisites')
        });
  }

  getNestedMinisites(filter) {
    this.constructiveDetailService.getMinisites(filter)
      .pipe(first())
      .subscribe( (data: any) => {
          this.nestedDataSourceMinisites.data = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de minisites')
        });
  }

  getDocType() {
    this.doc_types = this.elementTypeService.getDocType();
  }

  getCategories() {
    this.categoryService.getCategories('')
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.categories = data;
        },
        error => {
          this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de categorías')
        });
  }

  getNestedCategories(filter) {
    this.categoryService.getCategories(filter)
      .pipe(first())
      .subscribe( (data: any) => {
        this.nestedDataSource.data = data;
      },
      error => {
        this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al obtener los datos de categorías')
      });
  }

  loadAutocompletes() {
    let infoPaginateConstructiveDetails: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    if(this.isAdmin) {
      infoPaginateConstructiveDetails.msi = 1;
    }

    this.filteredConstructiveDetails = this.extendedFormGroup.get('linkRelatedConstructionDetails').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateConstructiveDetails.text = value;
          return this.constructiveDetailService.findConstructiveDetails(infoPaginateConstructiveDetails)
        })
      );

    let infoPaginateArticle: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    if(this.isAdmin) {
      infoPaginateArticle.msi = 1;
    }

    this.filteredArticles = this.extendedFormGroup.get('linkRelatedArticles').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateArticle.text = value;
          return this.articleService.findArticles(infoPaginateArticle)
        })
      );

    let infoPaginateProject: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    if(this.isAdmin) {
      infoPaginateProject.msi = 1;
    }

    this.filteredProjects = this.extendedFormGroup.get('linkRelatedProjects').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateProject.text = value;
          return this.projectService.findProjects(infoPaginateProject)
        })
      );

    let infoPaginateProjectLink: PaginatorInterface = {
      hasNext: false,
      items: 0,
      limit: 200,
      page: 1,
      text: '',
      category: ''
    };

    if(this.isAdmin) {
      infoPaginateProjectLink.msi = 1;
    }

    this.filteredProject = this.articleFormGroup.get('project').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          infoPaginateProjectLink.text = value;
          return this.projectService.findProjects(infoPaginateProjectLink)
        })
      );
  }

  // DROP ZONE
  droppedHeaderImg($files) {
    let item: FileItem = this.uploaderHeaderImg.queue[this.uploaderHeaderImg.queue.length - 1]
    this.uploaderHeaderImg.clearQueue();
    this.uploaderHeaderImg.queue.push(item);
  }

  onFileSelectedHeaderImg() {
    let item: FileItem = this.uploaderHeaderImg.queue[this.uploaderHeaderImg.queue.length - 1]
    this.uploaderHeaderImg.clearQueue();
    this.uploaderHeaderImg.queue.push(item);
  }

  droppedTechnicalDetailImg($files) {
    let item: FileItem = this.uploaderTechnicalDetailImg.queue[this.uploaderTechnicalDetailImg.queue.length - 1]
    this.uploaderTechnicalDetailImg.clearQueue();
    this.uploaderTechnicalDetailImg.queue.push(item);
  }

  onFileSelectedTechnicalDetailImg() {
    let item: FileItem = this.uploaderTechnicalDetailImg.queue[this.uploaderTechnicalDetailImg.queue.length - 1]
    this.uploaderTechnicalDetailImg.clearQueue();
    this.uploaderTechnicalDetailImg.queue.push(item);
  }

  droppedSequenceImg($files) {
    for (var i = 0; i < $files.length; i++) {
      let file = new Media();
      file.file = $files[i];
      file.name = $files[i].name;
      if (this.sequenceFiles.length <= 12) this.sequenceFiles.push(file)
    }

    this.uploaderSequenceImg.clearQueue();
  }

  onFileSelectedSequenceImg() {
    this.uploaderSequenceImg.queue.forEach(element => {
      let file = new Media();
      file.file = element._file;
      file.name = element._file.name;
      if (this.sequenceFiles.length <= 12) this.sequenceFiles.push(file)
    })

    this.uploaderSequenceImg.clearQueue();
  }

  removeSequenceFile(index) {
    this.sequenceFiles.splice(index, 1);
  }

  addDUrlDocument() {
    let file = new Media();
    file.name = this.urlDocument;
    file.url = this.urlDocument;

    this.documentsFiles.push(file)
    this.urlDocument = "";
  }

  droppedDocuments($files) {
    for (var i = 0; i < $files.length; i++) {
      let file = new Media();
      file.file = $files[i];
      file.name = $files[i].name;

      this.documentsFiles.push(file)
    }

    this.uploaderDocuments.clearQueue();
  }

  onFileSelectedDocuments() {
    this.uploaderDocuments.queue.forEach(element => {
      let file = new Media();
      file.file = element._file;
      file.name = element._file.name;
      this.documentsFiles.push(file)
    })

    this.uploaderDocuments.clearQueue();
  }

  removeDocumentFile(index) {
    this.documentsFiles.splice(index, 1);
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  // DROP ZONE

  addComplementaryField() {
    this.complementary_fields.push(new Attributes())
  }

  removeComplementaryField(index) {
    this.complementary_fields.splice(index, 1);
  }

  // RELATED CONSTRUCTIVE DETAILS
  displayFnRelatedConstructiveDetail(data: ConstructiveDetail) {
    if (data) { return data.title.es; }
  }

  addRelatedConstructiveDetail() {
    if(this.extendedFormGroup.get('linkRelatedConstructionDetails').value.id) {
      let data = new Link();
      data.referenceId = this.extendedFormGroup.get('linkRelatedConstructionDetails').value.id;
      data.name = this.extendedFormGroup.get('linkRelatedConstructionDetails').value.title.es;
      data.slug = this.extendedFormGroup.get('linkRelatedConstructionDetails').value.slug;
      data.type = "constructiveDetail";
      data.url = `/constructive-details/${this.extendedFormGroup.get('linkRelatedConstructionDetails').value.slug}`;

      this.linkRelatedConstructionDetails.push(data)

      this.extendedFormGroup.get('linkRelatedConstructionDetails').setValue('');
    }
  }

  removeRelatedConstructiveDetail(index) {
    this.linkRelatedConstructionDetails.splice(index, 1);
  }
  // RELATED CONSTRUCTIVE DETAILS
  // RELATED ARTICLES
  displayFnRelatedArticle(data: ConstructiveDetail) {
    if (data) { return data.title.es; }
  }

  addRelatedArticle() {
    if(this.extendedFormGroup.get('linkRelatedArticles').value.id) {
      let data = new Link();
      data.referenceId = this.extendedFormGroup.get('linkRelatedArticles').value.id;
      data.name = this.extendedFormGroup.get('linkRelatedArticles').value.title.es;
      data.slug = this.extendedFormGroup.get('linkRelatedArticles').value.slug;
      data.type = "article";
      data.url = `/articles/${this.extendedFormGroup.get('linkRelatedArticles').value.slug}`;

      this.linkRelatedArticles.push(data)

      this.extendedFormGroup.get('linkRelatedArticles').setValue('');
    }
  }

  removeRelatedArticle(index) {
    this.linkRelatedArticles.splice(index, 1);
  }
  // RELATED ARTICLES
  // RELATED PROJECTS
  displayFnRelatedProject(data: Project) {
    if (data) { return data.title.es; }
  }

  addRelatedProject() {
    if(this.extendedFormGroup.get('linkRelatedProjects').value.id) {
      let data = new Link();
      data.referenceId = this.extendedFormGroup.get('linkRelatedProjects').value.id;
      data.name = this.extendedFormGroup.get('linkRelatedProjects').value.title.es;
      data.slug = this.extendedFormGroup.get('linkRelatedProjects').value.slug;
      data.type = "project";
      data.url = `/projects/${this.extendedFormGroup.get('linkRelatedProjects').value.slug}`;

      this.linkRelatedProjects.push(data)

      this.extendedFormGroup.get('linkRelatedProjects').setValue('');
    }
  }

  removeRelatedProject(index) {
    this.linkRelatedProjects.splice(index, 1);
  }
  // RELATED PROJECTS
  // LINKED PROJECT
  displayFnProject(data: Project) {
    if (data) { return data.title.es; }
  }

  addProject() {
    if(this.articleFormGroup.get('project').value.id) {
      let data = new Link();
      data.referenceId = this.articleFormGroup.get('project').value.id;
      data.name = this.articleFormGroup.get('project').value.title.es;
      data.slug = this.articleFormGroup.get('project').value.slug;
      data.type = "project";
      data.url = `/projects/${this.articleFormGroup.get('project').value.slug}`;

      this.linkProject = data;

      this.articleFormGroup.get('project').setValue('');
    }
  }

  removeProject() {
    this.linkProject = new Link();
  }
  // LINKED PROJECTS

  onFileURNInput(event) {
    if(event.target.files && event.target.files.length) {

      let reader = new FileReader();
      const file = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.URNFile = file;
      }
    }
  }

  onSubmit(form: FormGroup)
  {
    this.validating = true;
    if(form.valid) {
      this.validating = false;
      this.step = 'second';
    }
  }

  setFocus($event) {
    $event.focus();
  }

  cancel() {
    this._location.back();
  }

  dateFormat(date, separator = '/') {
    function twoDigit(n) { return (n < 10 ? '0' : '') + n; }

    return `${date.getFullYear()}${separator}${twoDigit(date.getMonth() + 1)}${separator}${twoDigit(date.getDate())}`;
  }

  copyToClipboard(text: string){
    this.clipboardService.copyFromContent(text);
    this.ngxToastrService.typeSuccess("Copiado", "Url copiada al portapapeles");
  }

  async onPublish(form: FormGroup) {
    this.validating = true;

    if(form.valid) {
      this.saving = true;
      this.validating = false;
      let data = new ConstructiveDetail();

      data.datePublish = this.dateFormat(new Date(form.value.date_publish));
      data.datePublish = `${data.datePublish} ${form.value.hour_publish}:00`;

      let title = new MLItem();
      title.es = this.articleFormGroup.controls['title'].value;
      title.en = this.articleFormGroup.controls['title_en'].value;
      data.title = title;

      data.author = this.articleFormGroup.controls['author'].value;
      data.authorLink = this.articleFormGroup.controls['authorLink'].value;
      data.project = '';

      if(this.linkProject.referenceId) {
        data.project = this.linkProject.referenceId;
      }

      data.collaborators = this.articleFormGroup.controls['collaborators'].value;
      data.location = this.articleFormGroup.controls['location'].value;
      data.photographer = this.articleFormGroup.controls['photographer'].value;
      data.photographerLink = this.articleFormGroup.controls['photographerLink'].value;
      data.year = this.articleFormGroup.controls['year'].value;
      data.opened = form.value.opened;
      data.draft = form.value.draft;
      if(this.isAdmin && this.isEdit){
        data.createdByTectonica = form.value.createdByTectonica;
      }
      if(this.isAdmin){
        data.viewerVisibility = form.value.viewerVisibility;
        data.minisites = this.extendedFormGroup.controls['minisites'].value;
      }

      data.category = this.articleFormGroup.controls['block'].value;
      data.complementaryFields = this.complementary_fields;
      data.linkRelatedConstructionDetails = this.linkRelatedConstructionDetails;
      data.linkRelatedArticles = this.linkRelatedArticles;
      data.linkRelatedProjects = this.linkRelatedProjects;

      data.keywords = this.articleFormGroup.controls['keywords'].value.split(',');

      if(this.uploaderHeaderImg.queue.length > 0) {
        let headerImage = new Media();

        let imageData = new FormData();

        imageData.append('media', this.uploaderHeaderImg.queue[0]._file, this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name))
        imageData.append('type', this.uploaderHeaderImg.queue[0]._file.type.split('/')[1]);
        imageData.append('name', this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name));

        await this.mediaService.uploadImage(imageData)
          .toPromise().then( (url) => {
            headerImage.url = url.toString();
            headerImage.type = this.uploaderHeaderImg.queue[0]._file.type.split('/')[1];
            headerImage.name = this.slugifyPipe.transform(this.uploaderHeaderImg.queue[0]._file.name);
           })

        data.headerImage = headerImage;
      }

      if(this.uploaderTechnicalDetailImg.queue.length > 0) {
        let technicalDrawings = new Media();

        let imageData = new FormData();

        imageData.append('media', this.uploaderTechnicalDetailImg.queue[0]._file, this.slugifyPipe.transform(this.uploaderTechnicalDetailImg.queue[0]._file.name))
        imageData.append('type', this.uploaderTechnicalDetailImg.queue[0]._file.type.split('/')[1]);
        imageData.append('name', this.slugifyPipe.transform(this.uploaderTechnicalDetailImg.queue[0]._file.name));

        await this.mediaService.uploadImage(imageData)
          .toPromise().then( (url) => {
            technicalDrawings.url = url.toString();
            technicalDrawings.type = this.uploaderTechnicalDetailImg.queue[0]._file.type.split('/')[1];
            technicalDrawings.name = this.slugifyPipe.transform(this.uploaderTechnicalDetailImg.queue[0]._file.name);
           })

        data.technicalDrawings = technicalDrawings;
      }

      let documents: Media[] = [];

      for( const file of this.documentsFiles ) {
        if(file.file) {
          let fileData = new FormData();
          fileData.append('media', file.file, this.slugifyPipe.transform(file.file.name))
          if(file.file.name.substr(file.file.name.lastIndexOf('.') + 1).toLowerCase() == 'dwg'){
            fileData.append('type', 'dwg');
          }else{
            fileData.append('type', file.file.type.split('/')[1]);
          }
          fileData.append('name', this.slugifyPipe.transform(file.file.name));
          fileData.append('subscribersOnly', file.file.subscribersOnly);

          await this.mediaService.uploadImage(fileData)
            .toPromise().then( (url) => {
              file.url = url.toString();
              delete file.file;
             })
        }

        documents.push(file);

      }

      data.documents = documents;

      let images: Media[] = [];

      for( const file of this.sequenceFiles ) {
        if(file.file) {
          let fileData = new FormData();
          fileData.append('media', file.file, this.slugifyPipe.transform(file.file.name))
          fileData.append('type', file.file.type.split('/')[1]);
          fileData.append('name', this.slugifyPipe.transform(file.file.name));

          await this.mediaService.uploadImage(fileData)
            .toPromise().then( (url) => {
              file.url = url.toString();
              delete file.file;
             })
        }

        images.push(file);

      }

      data.images = images;

      let description = new MLItem();
      description.es = form.value.content;
      description.en = form.value.content_en;
      data.description = description;

      let descriptionSubscribers = new MLItem();
      descriptionSubscribers.es = form.value.contentSubscribers;
      descriptionSubscribers.en = form.value.contentSubscribers_en;
      data.descriptionSubscribers = descriptionSubscribers;

      if(this.data.id) {
        this.constructiveDetailService.updateConstructiveDetail(data, this.data.id)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.saving = false;

              this.uploadURN(this.data.id)

              this.router.navigate(['/account/publications/constructive-detail/ok'])

              swal(
                'Datos de Detalle Constructivo',
                'Sus datos de detalle constructivo han sido actualizados.',
                'success'
              )
            },
            error => {
              this.saving = false;
              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al almacenar los datos del material')
            });
      } else  {
        this.constructiveDetailService.addConstructiveDetail(data)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.saving = false;

              this.uploadURN(data.id)

              swal(
                'Datos de Detalle Constructivo',
                'Sus datos de detalle constructivo han sido creados.',
                'success'
              )

              this.router.navigate(['/account/publications/constructive-detail/ok'])
            },
            error => {
              this.saving = false;
              this.ngxToastrService.typeError('Error en la consulta!', 'Se ha producido un error al almacenar el detalle constructivo.')
            });
      }
    }
  }

  uploadURN(id: string) {
    if(this.URNFile) {
      let urnData = new FormData();

      urnData.append('object', this.URNFile, this.URNFile.name)
      urnData.append('id', id);

      this.mediaService.uploadURN(urnData)
        .pipe(first())
        .subscribe(
          (data: any) => {
            console.log(data)
          },
          error => {
            console.log(error)
          });
    }
  }
}
