
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { PaginatorInterface } from 'app/interfaces/paginator.interface';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ContentService {
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getContent(slug: string, minisite: number = 0) {
    return this.http.get(`${environment.urlApi}/content/${slug}?msi=${minisite}`, { headers: this.getHeaders()});
  }

  getContentsRemaining() {
    return this.http.get(`${environment.urlApi}/content/remaining`);
  }

  getContentRemainingAdmin(email) {
    return this.http.get(`${environment.urlApi}/content/remaining/${email}`);
  }

  getContentTotalAdmin(email) {
    return this.http.get(`${environment.urlApi}/content/total/${email}`);
  }

  getContentTotal() {
    return this.http.get(`${environment.urlApi}/content/total`);
  }

  findContents(infoPaginate: PaginatorInterface) {
    let objFilter = {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
      'filter[text][regex]': infoPaginate.text || '',
    }
    if(infoPaginate.category) objFilter['filter[category][in]'] = infoPaginate.category;
    if(infoPaginate.msi) objFilter['msi'] = infoPaginate.msi;
    if(infoPaginate.mso) objFilter['mso'] = infoPaginate.mso;
    const params = new HttpParams({fromObject: objFilter});

    return this.http.get<any>(`${environment.urlApi}/content/`, { params });
  }

  getOwnContents() {
    return this.http.get(`${environment.urlApi}/content/own`);
  }

  getContentIndex() {
    return this.http.get(`${environment.urlApi}/content/`);
  }

  getRelatedContent(id: string) {
    return this.http.get(`${environment.urlApi}/content/related/${id}`, { headers: this.getHeaders()});
  }

  addBookmark(data) {
    return this.http.post(`${environment.urlApi}/bookmark/`, data, { headers: this.getHeaders()})
      .pipe(
        map( result => result)
      );
  }

  deleteBookmark(id: string) {
    return this.http.delete(`${environment.urlApi}/bookmark/${id}`, { headers: this.getHeaders()});
  }

  getBookmarks(userId: string) {
    return this.http.get(`${environment.urlApi}/bookmark/${userId}`);
  }

  getForReview(infoPaginate: PaginatorInterface) {
    let objFilter = {
      'page': infoPaginate.page.toString(),
      'limit': infoPaginate.limit.toString(),
      'filter[text][regex]': infoPaginate.text || '',
    }

    const params = new HttpParams({fromObject: objFilter});

    return this.http.get<any>(`${environment.urlApi}/content/for-review`, { params });
  }

  addReview(data: any, id: string) {
    return this.http.post(`${environment.urlApi}/content/${id}/review`, data)
      .pipe(
        map( result => result)
      );
  }

  getTypologies() {
    return this.http.get(`${environment.urlApi}/typology/`);
  }

  updateFeatured(type: string, data: any) {
    // El tipo constructive-detail se envía como constructiveDetail para que el backend lo reconozca
    if(type === 'constructive-detail') {
      type = 'constructiveDetail';
    }
    return this.http.post(`${environment.urlApi}/content/${type}/featured`, data);
  }

  getHeaders(){
    let headers = {
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    };

    //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let userCookie = null;
    if(this.cookieService.check('loggedin')){
      userCookie = this.cookieService.get('loggedin');
    }
    let currentUser = JSON.parse(userCookie);
    if (currentUser && currentUser.token) {
      headers['Authorization'] = `Bearer ${currentUser.token}`;
    }

    return headers;
  }
}
